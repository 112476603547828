import React from 'react'
import { Box, Button, Typography } from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import { GoogleLogin } from '@react-oauth/google'
import useMediaQuery from '@mui/material/useMediaQuery'

import ClasslinkLogo from '../../images/ClassLinkLogo.png'
import CleverLogo from '../../images/Clever-logo-edit.png'
import EsLogo_white from '../../images/ESLogo_white.png'

import { PasswordField, TextField } from '../shared'

const ForgotPasswordText = styled(Typography)(({ theme }) => ({
  color: '#fff',
  fontSize: 12,
  cursor: 'pointer',

  marginBottom: theme.spacing(2),

  display: 'flex'
}))

const LoginButton = styled(Button)(({ theme }) => ({
  minWidth: 300,
  height: 51,
  fontSize: 20,
  fontWeight: 'bold',
  backgroundColor: theme.palette.success.main,
  color: '#fff',

  '&:hover': {
    backgroundColor: theme.palette.success.main
  },

  [theme.breakpoints.up('md')]: {
    minWidth: 398
  }
}))

const LoginStatusErrorMessage = styled('div')(({ theme }) => ({
  textAlign: 'start',

  color: '#fff',
  marginTop: theme.spacing(2),
  padding: theme.spacing(),

  background: theme.palette.error.main
}))

const Logo = styled('img')(({ theme }) => ({
  height: 62,
  marginBottom: theme.spacing(3),

  [theme.breakpoints.only('xs')]: {
    width: 300
  }
}))

const LogoContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center'
}))

const OrText = styled(Typography)(({ theme }) => ({
  color: '#fff',
  width: '100%',

  textAlign: 'center',
  borderBottom: '1px solid #fff',
  lineHeight: '0.07em',
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(),

  span: {
    background: theme.palette.primary.main,
    padding: '0 10px',
    fontSize: 25
  }
}))

const StyledImg = styled('img')(({ theme }) => ({
  width: 40,
  height: 40,
  marginRight: theme.spacing()
}))

const StyledTextField = styled(TextField)(({ theme }) => ({
  width: '300px !important',
  marginBottom: theme.spacing(2),
  marginRight: 0,

  '& label.Mui-focused': {
    color: 'white'
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'white'
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'white'
    },
    '&:hover fieldset': {
      borderColor: 'white'
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white'
    }
  },

  '.MuiInputLabel-root': {
    color: 'white',
    fontSize: 15,
    '&$focusedLabel': {
      color: 'white'
    },
    '&$erroredLabel': {
      color: theme.palette.error.main
    }
  },

  '.Mui-focused': {
    color: '#fff !important'
  },

  '.MuiInput-underline:before': {
    borderBottom: '1px solid white'
  },

  [theme.breakpoints.up('md')]: {
    width: '398px !important'
  }
}))

const ThirdPartyButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'backgroundColor'
})(({ backgroundColor, theme }) => ({
  minWidth: 300,
  height: 40,

  marginTop: theme.spacing(2),

  fontSize: 20,
  background: backgroundColor,

  '&:hover': {
    background: backgroundColor,
    cursor: 'pointer'
  },

  textTransform: 'none',

  display: 'flex',
  justifyContent: 'flex-start',
  paddingLeft: 4,

  [theme.breakpoints.up('md')]: {
    width: 398
  }
}))

const MainContent = ({
  formState,
  handleKeyDown,
  login,
  loginStatusErrorMessage,
  onSuccessGoogle,
  setView,
  updateState
}) => {
  const theme = useTheme()
  const isMdOrLess = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <>
      <LogoContainer>
        <Logo alt='Enriching Teachers Logo' src={EsLogo_white} />
      </LogoContainer>
      <StyledTextField
        dataTestId='email-address'
        id='email-address'
        label='EMAIL ADDRESS'
        name='emailAddress'
        onChange={updateState}
        value={formState.emailAddress}
      />
      <form onSubmit={(e) => e.preventDefault}>
        <PasswordField
          allWhite={true}
          sx={{ width: { xs: 300, md: 398 } }}
          onKeyDown={(e) => handleKeyDown(e.keyCode)}
          label='PASSWORD'
          name='passwordFromClient'
          onChange={updateState}
          value={formState.passwordFromClient}
        />
      </form>
      <ForgotPasswordText onClick={() => setView(1)}>
        Forgot Password?
      </ForgotPasswordText>
      <LoginButton
        data-testid='login-button'
        disabled={!formState.emailAddress || !formState.passwordFromClient}
        onClick={login}>
        Sign in
      </LoginButton>
      <OrText>
        <span>or</span>
      </OrText>
      <Box sx={{ marginTop: 2 }}>
        <GoogleLogin
          onSuccess={(credentialResponse) => {
            onSuccessGoogle(credentialResponse)
          }}
          onError={() => {
            console.log('Login Failed')
          }}
          size='large'
          shape='rectangular'
          width={isMdOrLess ? 300 : 398}
        />
      </Box>
      <a
        href={`${process.env.REACT_APP_CLASSLINK_REDIRECT_LINK}`}
        style={{ textDecoration: 'none' }}>
        <ThirdPartyButton backgroundColor='#0a4d7f'>
          <StyledImg alt='Login With Classlink' src={ClasslinkLogo} />
          <Typography sx={{ color: '#fff' }}>Sign In With Classlink</Typography>
        </ThirdPartyButton>
      </a>
      <a
        href={`${process.env.REACT_APP_CLEVER_REDIRECT_LINK}`}
        style={{ textDecoration: 'none' }}>
        <ThirdPartyButton backgroundColor='#4274f6'>
          <StyledImg alt='Login With Clever' src={CleverLogo} />
          <Typography sx={{ color: '#fff' }}>Sign In With Clever</Typography>
        </ThirdPartyButton>
      </a>
      {loginStatusErrorMessage !== '' && (
        <LoginStatusErrorMessage>
          <Typography sx={{ fontSize: 22 }}>
            There was a problem logging in:
          </Typography>
          <Typography>{loginStatusErrorMessage}</Typography>
        </LoginStatusErrorMessage>
      )}
    </>
  )
}

export default MainContent
