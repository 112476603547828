const ESU_ROOT_URL = 'https://www.enrichingstudents.com/university'

export const esuUrls = {
  ABSENT_STUDENTS:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/360056608052',
  ADD_STAFF:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/360057063791',
  ADD_STUDENT:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/360056608072',
  ADD_SUBSTITUTE:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/360030109351-Manage-Substitutes-',
  ADJUSTED_OFFERINGS:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/360057062551',
  ADJUSTED_SERIES: `${ESU_ROOT_URL}`,
  ADVANCED_APPOINTMENTS:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/360057062571',
  APPOINTMENTS:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/360056534132',
  BLOCKED_DATE:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/360056608212',
    CONFIGURATION: '',
  CREATE_ADJUSTED_OFFERINGS:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/360057062551',
  CREATE_APPOINTMENT_TYPE:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/360002885691-Manage-Appointment-Types',
  CREATE_ATTENDANCE_TYPE:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/360002885871-Manage-Attendance-Types',
  CREATE_BLOCKED_DATE:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/360056608212',
  CREATE_DEPARTMENT:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/360031576971-Manage-Departments-Advisory-Groups',
  CREATE_MESSAGE:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/360057155071',
  CREATE_ROSTER:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/360002734112-Building-Your-Roster',
  DASHBOARD: `https://enrichingstudents.zendesk.com/hc/en-us/articles/360057156411`,
  DELETE_MULTIPLE_APPOINTMENTS: '',
  EDIT_PERIOD:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/360029769512-Manage-School-Periods',
  EXPORT_DATA:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/360029769512-Manage-School-Periods',
  EMAIL_LOGS:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/360057357411',
  EMAIL_NOTIFICATIONS:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/360056534092',
  IMPORT_DATA:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/360030971611-Manually-Import-Data-using-our-Admin-tools',
  MANAGE_APPOINTMENT_TYPES: `https://enrichingstudents.zendesk.com/hc/en-us/articles/360002885691-Manage-Appointment-Types`,
  MANAGE_ATTENDANCE_TYPES: `https://enrichingstudents.zendesk.com/hc/en-us/articles/360002885871-Manage-Attendance-Types`,
  MANAGE_DAY_TYPES:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/360056993131',
  MANAGE_DEPARTMENTS:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/360031576971-Manage-Departments-Advisory-Groups',
  MANAGE_HOMEROOM_ROSTERS: `${ESU_ROOT_URL}`,
  MANAGE_INVALID_EMAILS:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/360056533932',
  MANAGE_PERIODS:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/360029769512-Manage-School-Periods',
  MANAGE_MESSAGES:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/360057155071',
  MANAGE_REPORTS:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/360056890192',
  MANAGE_ROSTERS:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/360002734112-Building-Your-Roster',
  MANAGE_STAFF:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/360057063791',
  MANAGE_STUDENTS:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/360056993011',
  MANAGE_SUBSTITUTES:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/360030109351-Manage-Substitutes-',
  MASS_SCHEDULE:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/360056608272',
  ORDER_GRADES:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/360056992951',
  SCHOOL_CALENDAR: `${ESU_ROOT_URL}`,
  SCHOOL_NOTIFICATIONS:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/360056608292',
  STUDENT_PROFILE: 'https://enrichingstudents.zendesk.com/hc/en-us/',
  STUDENTS:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/360056993011',
  STUDENT_REQUESTS:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/360056608312',
  TAKE_ATTENDANCE:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/360057062731',
  UNSCHEDULED_STUDENTS:
    'https://enrichingstudents.zendesk.com/hc/en-us/articles/360056608332'
}
