import React from 'react'
import { styled } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'

const MessagesContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  position: 'absolute',
  width: '100vw',
  padding: '8px 24px',
  maxHeight: 150,
  overflow: 'auto'
}))

const Messages = ({ messages, messagesRef }) => {
  return (
    <>
      {messages.length > 0 && (
        <MessagesContainer ref={messagesRef}>
          {messages.map((message, index) => (
            <Typography
              key={index}
              dangerouslySetInnerHTML={{ __html: message }}
              sx={{ color: '#fff' }}
            />
          ))}
        </MessagesContainer>
      )}
    </>
  )
}

export default Messages
