import { loginStatuses as ls } from '../../constants'

export const validateLoginStatus = ({ emailAddress, loginStatus }) => {
  let message = ''

  switch (loginStatus) {
    case ls.schoolPreventsStudentLogin:
      message = `The school associated with ${emailAddress} does not allow students to login to Enriching Students.`
      break
    case ls.subIsNotSetToImpersonate:
      message = `${emailAddress} is marked as a substitute. An Admin has to authorize this account to impersonate a staffer to login to Enriching Students.`
      break
    case ls.cleverDidNotReturnEmailAddress:
      message = 'Clever did not did not authorize the email address provided.'
      break
    case ls.cleverDidNotAuthorize:
      message = 'Clever did not authorize the email address provided.'
      break
    case ls.cleverTokenNotProvided:
      message = 'Clever did not provide a token for the email address.'
      break
    case ls.loginTokensExpired:
      message = `The login tokens for ${emailAddress} have expired.`
      break
    case ls.emailDoesNotExistInEnrichingStudents:
      message = `The address ${emailAddress} does not exist in Enriching Students.`
      break
    case ls.unableToValidateGoogleCredentials:
      message = 'Google is unable to validate the email address used.'
      break
    case ls.failedDueToErrorWithGoogle:
      message =
        'Google has flagged the account provided as having too many errors.'
      break
    case ls.failedDueToTooManyActiveAccounts:
      message = `${emailAddress} is associated with multiple schools and cannot login.`
      break
    case ls.failedDueToAccountBeingInactiveOrSoftDeleted:
      message = `${emailAddress} has been marked as inactive or soft deleted. You will need to contact an Admin in your school to reactivate this account.`
      break
    case ls.failedDueToSiteBeingInTestMode:
      message =
        'Enriching Students is currently in test mode and cannot be logged into.'
      break
    case ls.failedDueToAccountLockedOut:
      message = `${emailAddress} has been locked out of Enriching Students.`
      break
    case ls.failedDueToSchoolAccountInactive:
      message = `The school associated with ${emailAddress} has been marked as inactive for Enriching Students.`
      break
    case ls.failedDueToBadEmail:
      message = `Either the email or password were invalid. Please change your credentials and try again.`
      break
    case ls.unknown:
      message =
        'Something went wrong with the login. Tech support has been notified. We apologize for the confusion.'
      break
    case ls.succeeded:
      message = ''
      break
    default:
      throw new Error('Unknown Login Error')
  }
  return message
}
