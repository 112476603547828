import React, { useState } from 'react'
import axios from 'axios'
import { Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import { styled } from '@mui/material/styles'
import { useForm } from 'react-hook-form'

import { Button, TextField } from '../shared'
import { ROOT } from '../../constants'

import Loading from './Loading'

const StyledTextField = styled(TextField)(({ theme }) => ({
  width: '300px !important',
  marginBottom: theme.spacing(2),

  '& label.Mui-focused': {
    color: 'white'
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'white'
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'white'
    },
    '&:hover fieldset': {
      borderColor: 'white'
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white'
    }
  },

  '.MuiInputLabel-root': {
    color: 'white',
    fontSize: 15,
    '&$focusedLabel': {
      color: 'white'
    },
    '&$erroredLabel': {
      color: theme.palette.error.main
    }
  },

  '.Mui-focused': {
    color: '#fff !important'
  },

  '.MuiInput-underline:before': {
    borderBottom: '1px solid white'
  },

  [theme.breakpoints.up('md')]: {
    width: '456px !important'
  }
}))

const ForgotPassword = ({ emailAddress = '', toggleView }) => {
  const { enqueueSnackbar } = useSnackbar()

  const [posting, setPosting] = useState(false)

  const { errors, handleSubmit, register } = useForm({
    mode: 'onBlur'
  })

  const onSubmit = (formValues) => {
    setPosting(true)

    axios
      .post(`${ROOT}passwordresetrequest/requestreset`, {
        emailAddress: formValues.emailAddress
      })
      .catch(() => {
        enqueueSnackbar('Unable to send an email', {
          variant: 'error'
        })
      })
      .then(() => {
        enqueueSnackbar(
          'An email was sent with instructions to reset your password.',
          {
            variant: 'success'
          }
        )

        setPosting(false)
        toggleView(0)
      })
  }

  if (posting) return <Loading sx={{ color: '#fff' }} text='Sending Email' />

  return (
    <div>
      <Typography sx={{ color: '#fff' }} variant='h5'>
        Forgot Password?
      </Typography>
      <Typography sx={{ color: '#fff' }}>
        Enter your email address and we'll send you an email to reset your
        password.
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledTextField
          dataTestId='email-address'
          defaultValue={''}
          error={Boolean(errors.emailAddress)}
          helperText={errors.emailAddress && errors.emailAddress.message}
          inputRef={register({
            required: 'Please enter an Email Address',
            pattern: {
              value:
                /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/,
              message: 'This email address is invalid'
            }
          })}
          label='Email Address'
          name='emailAddress'
        />
        <div>
          <Button
            esButtonClass='cancelButton'
            label='Cancel'
            marginRight
            onClick={() => toggleView(0)}
            sx={{
              color: '#fff',

              '&:hover': {
                background: '#959292'
              }
            }}
          />
          <Button label='Send Email' type='submit' />
        </div>
      </form>
    </div>
  )
}

export default ForgotPassword
