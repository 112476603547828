import React, { useCallback, useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import axios from 'axios'

import { loginStatuses as ls, ROOT } from '../../constants'
import { sortByCollatingField } from '../../helpers'
import { useQuery } from '../../hooks/useQuery'

import ForgotPassword from './ForgotPassword'
import Loading from './Loading'
import MainContent from './MainContent'
import MultiSchools from './MultiSchools'
import { validateLoginStatus } from './validateLoginStatus'

export const Container = styled('div')(({ theme }) => ({
  height: '100%',
  width: 300,

  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: 'auto',

  paddingTop: theme.spacing(3),
  paddingLeft: theme.spacing(),
  paddingRight: theme.spacing(),

  [theme.breakpoints.up('sm')]: {
    paddingTop: 0,
    justifyContent: 'center',

    paddingLeft: 0,
    paddingRight: 0
  },

  [theme.breakpoints.up('md')]: {
    width: 'auto',

    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',

    textAlign: 'center'
  }
}))

const LoginContainer = () => {
  const [loginStatusErrorMessage, setLoginStatusErrorMessage] = useState('')
  const [formState, setFormState] = useState({
    emailAddress: '',
    passwordFromClient: ''
  })
  const [posting, setPosting] = useState(false)
  const [users, setUsers] = useState([])
  const [view, setView] = useState(0)

  let query = useQuery()

  const code = query.get('code')
  const loginMethod = query.get('login')

  const handleCancelClick = () => {
    setFormState({
      emailAddress: '',
      passwordFromClient: ''
    })

    setUsers([])
  }

  const handleKeyDown = (keyCode) => {
    if (keyCode === 13) {
      login()
    }
  }

  const handleLoginResponse = useCallback(
    (data) => {
      if (data.length > 1) {
        // This user is multiple schools
        setUsers(data.sort(sortByCollatingField(true, 'schoolBrandName')))
        setPosting(false)
      } else {
        // User is in single school
        if (data[0].loginStatus === ls.succeeded) {
          // Single user is authenticated and we should redirect to data.redirectUrl
          window.location.href = data[0].redirectUrl
        } else {
          // Validate error to display on screen
          setLoginStatusErrorMessage(
            validateLoginStatus({
              emailAddress: formState.emailAddress,
              loginStatus: data[0].loginStatus
            })
          )

          setPosting(false)
        }
      }
    },
    [formState.emailAddress]
  )

  const login = () => {
    setPosting(true)

    axios
      .post(
        `${ROOT}login/validatecredential`,
        {
          emailAddress: formState.emailAddress,
          passwordFromClient: formState.passwordFromClient
        },
        { withCredentials: true }
      )
      .then((response) => handleLoginResponse(response.data))
      .catch((error) => {
        setPosting(false)
      })
  }

  const onSuccessGoogle = (response) => {
    setPosting(true)

    if (!response?.credential) {
      setLoginStatusErrorMessage(
        'Google did not return a Credential correctly, we cannot log you in at this time.'
      )
      setPosting(false)
    }

    axios
      .get(`${ROOT}login/validategooglejwt?credential=${response.credential}`, {
        withCredentials: true
      })
      .then((response) => {
        handleLoginResponse(response.data)
        setPosting(false)
      })
      .catch(() => {
        setLoginStatusErrorMessage(
          'There was a problem with Google and we cannot log you in at this time.'
        )
        setPosting(false)
      })
  }

  const updateState = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value })
  }

  //http://localhost:3000/?login=clever&code=someBadeCode
  useEffect(() => {
    if (loginMethod === 'clever') {
      if (code === null) {
        setLoginStatusErrorMessage(
          'Clever did not return a code correctly, we cannot log you in at this time.'
        )
        setPosting(false)
      }

      setPosting(true)

      axios
        .get(
          `https://esloginclever.azurewebsites.net/v1.0/loginClever/validateCleverCode?code=${code}`,
          {
            withCredentials: true
          }
        )
        .then((response) => {
          console.log(response, 'validate clever response')
          handleLoginResponse(response.data)
          setPosting(false)
        })
        .catch((error) => {
          console.log(error, 'error')
          console.log(error.response, 'error.response')
          setLoginStatusErrorMessage(
            'There was a problem with Clever and we cannot log you in at this time.'
          )
          setPosting(false)
        })
    }
  }, [code, handleLoginResponse, loginMethod])

  useEffect(() => {
    if (loginMethod === 'classlink') {
      if (code === null) {
        setLoginStatusErrorMessage(
          'Classlink did not return a code correctly, we cannot log you in at this time.'
        )
        setPosting(false)
      }

      setPosting(true)

      axios
        .get(
          `https://esloginviathirdparty.azurewebsites.net/v1.0/loginClasslink/validateClassLinkCode?code=${code}`,
          {
            withCredentials: true
          }
        )
        .then((response) => {
          console.log(response, 'validate Classlink response')
          handleLoginResponse(response.data)
          setPosting(false)
        })
        .catch((error) => {
          console.log(error, 'error')
          console.log(error.response, 'error.response')
          setLoginStatusErrorMessage(
            'There was a problem with Classlink and we cannot log you in at this time.'
          )
          setPosting(false)
        })
    }
  }, [code, handleLoginResponse, loginMethod])

  if (posting) return <Loading text='Signing Into Enriching Students' />

  return (
    <>
      {users.length > 1 ? (
        <Container>
          <MultiSchools
            formState={formState}
            handleCancelClick={handleCancelClick}
            users={users}
          />
        </Container>
      ) : (
        <Container>
          {view === 0 ? (
            <MainContent
              formState={formState}
              handleKeyDown={handleKeyDown}
              login={login}
              loginStatusErrorMessage={loginStatusErrorMessage}
              onSuccessGoogle={onSuccessGoogle}
              setView={setView}
              updateState={updateState}
            />
          ) : (
            <ForgotPassword
              emailAddress={formState.emailAddress}
              toggleView={setView}
            />
          )}
        </Container>
      )}
    </>
  )
}

export default LoginContainer
