import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { ROOT } from '../../constants'
import { styled } from '@mui/material/styles'

import triangles from '../../images/blue_triangles_rotated.png'
import { Typography } from '@mui/material'
import Loading from '../Login/Loading'

const Container = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,

  width: '100%',
  height: '100vh',

  [theme.breakpoints.up('sm')]: {
    backgroundImage: `url(${triangles})`,
    backgroundPosition: 'bottom right',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'auto 50%'
  }
}))

const Unsubscribe = (props) => {
  const { contactKey, studentId } = props.match.params

  const [isUnsubscribing, setIsUnsubscribing] = useState(false)
  const [message, setMessage] = useState('')

  useEffect(() => {
    setIsUnsubscribing(true)

    axios
      .post(
        `${ROOT}unsubscribe/studentContact`,
        {
          contactKey,
          studentId
        },
        { withCredentials: true }
      )
      .then((response) => {
        setMessage('You have been unsubscribed from Enriching Students emails.')
        setIsUnsubscribing(false)
      })
      .catch((error) => {
        setMessage('You have been unsubscribed from Enriching Students emails.')
        setIsUnsubscribing(false)
      })
  }, [contactKey, studentId])
  return (
    <Container>
      {isUnsubscribing ? (
        <Loading text='Unsubscribing from Enriching Students' />
      ) : (
        <Typography
          sx={{
            color: '#fff',
            textAlign: 'center',
            padding: 2,
            fontSize: 24
          }}>
          {message}
        </Typography>
      )}
    </Container>
  )
}

export default Unsubscribe
