import React from 'react'
import {
  AccountCircle,
  Dashboard,
  DateRange,
  EventAvailable,
  EventBusy,
  Group,
  Laptop,
  NotificationImportant,
  PermContactCalendar,
  School
} from '@mui/icons-material'
import moment from 'moment'

export const adminLinks = [
  {
    label: 'Configuration',
    to: '/configuration'
  },
  {
    label: 'Email Logs',
    to: '/emailLogs'
  },
  {
    label: 'Email Notifications',
    to: '/emailNotifications'
  },
  {
    hideOnMobile: true,
    label: 'Export Data',
    to: '/exportData'
  },
  {
    label: 'Manage Appointment Types',
    to: '/manageAppointmentTypes'
  },
  {
    label: 'Manage Attendance Types',
    to: '/manageAttendanceTypes'
  },
  {
    label: 'Manage Day Types',
    to: '/manageDayTypes'
  },
  {
    label: 'Manage Departments',
    to: '/manageDepartments'
  },
  {
    label: 'Manage Grades',
    to: '/orderGrades'
  },
  {
    label: 'Manage Homeroom Rosters',
    to: '/manageHomeroomRosters'
  },
  {
    label: 'Manage Invalid Emails',
    to: '/manageInvalidEmails'
  },
  {
    label: 'Manage Messages',
    to: '/manageMessages'
  },
  {
    label: 'Manage Periods',
    to: '/managePeriods'
  },
  {
    label: 'Manage Reports',
    to: '/manageReports'
  },
  {
    label: 'Manage Staff',
    to: '/manageStaff'
  },
  {
    label: 'Manage Student Attendance',
    to: '/manageStudentAttendance'
  },
  {
    label: 'Manage Substitutes',
    to: '/manageSubstitutes'
  },
  {
    label: 'Unscheduled Students',
    to: '/unscheduledStudents'
  }
]

export const stafferLinks = [
  {
    label: 'Dashboard',
    icon: <Dashboard />,
    to: '/'
  },
  {
    label: 'Appointments',
    icon: <EventAvailable />,
    to: '/appointments'
  },
  {
    label: 'Students',
    icon: <School />,
    to: '/students'
  },
  {
    label: 'Adjusted Offerings',
    icon: <Laptop />,
    to: '/adjustedOfferings/0'
  },
  {
    label: 'Block Date',
    icon: <EventBusy />,
    to: '/blockDate'
  },
  {
    label: 'Requests',
    icon: <PermContactCalendar />,
    to: '/studentRequests'
  },
  {
    label: 'Manage Student Groups',
    icon: <Group />,
    to: '/manageRosters'
  },
  {
    icon: <DateRange />,
    label: 'Mass Appointments',
    /* Date/StaffId/RosterId/PeriodId */
    to: `/massAppointments/${moment().format('YYYY-MM-DD')}/0/0/0`
  },
  {
    icon: <NotificationImportant />,
    label: 'School Notifications',
    to: '/schoolNotifications'
  },
  {
    label: 'My Account',
    icon: <AccountCircle />,
    to: '/myAccount'
  }
]

export const subLinks = [
  {
    label: 'Dashboard',
    icon: <Dashboard />,
    to: '/'
  },
  {
    label: 'Appointments',
    icon: <EventAvailable />,
    to: '/appointments'
  },
  {
    label: 'My Account',
    icon: <AccountCircle />,
    to: '/myAccount'
  }
]
