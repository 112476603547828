import React, { useState } from 'react'
import {
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel
} from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { styled } from '@mui/material/styles'

const StyledFormControl = styled(FormControl, {
  shouldForwardProp: (prop) => prop !== 'allWhite'
})(({ allWhite, theme }) => ({
  width: 250,
  marginTop: theme.spacing(0.5),
  marginBottom: theme.spacing(0.5),

  [theme.breakpoints.down('xs')]: {
    width: '100%'
  },

  '& label.Mui-focused': {
    color: allWhite && '#fff'
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: allWhite && '#fff'
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: allWhite && '#fff'
    },
    '&:hover fieldset': {
      borderColor: allWhite && '#fff'
    },
    '&.Mui-focused fieldset': {
      borderColor: allWhite && '#fff'
    }
  },

  '.MuiInputLabel-root': {
    color: allWhite && '#fff',
    '&$focusedLabel': {
      color: allWhite && '#fff'
    },
    '&$erroredLabel': {
      color: allWhite && theme.palette.error.main
    }
  },

  '.Mui-focused': {
    color: allWhite && '#fff !important'
  },

  '.MuiInput-underline:before': {
    borderBottom: allWhite && '1px solid #fff'
  }
}))

const StyledIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'allWhite'
})(({ allWhite, theme }) => ({
  color: allWhite && '#fff'
}))

const PasswordField = React.forwardRef(
  (
    {
      allWhite = false,
      dataTestId = 'password',
      focusedLabelClass,
      error,
      id = 'password-field',
      inputLabelRootClass,
      inputProps,
      label = 'Password',
      name,
      onChange,
      onKeyDown,
      underlineClass,
      sx,
      value
    },
    ref
  ) => {
    const [showPassword, setShowPassword] = useState('')

    const handleClickShowPassword = (e) => {
      setShowPassword(!showPassword)
    }

    const handleMouseDownPassword = (event) => {
      event.preventDefault()
    }

    return (
      <StyledFormControl
        allWhite={allWhite}
        ref={ref}
        error={Boolean(error)}
        sx={sx}
        variant='standard'>
        <InputLabel
          classes={{
            root: inputLabelRootClass,
            focused: focusedLabelClass
          }}
          htmlFor='password-field'>
          {label}
        </InputLabel>
        <Input
          classes={{
            underline: underlineClass
          }}
          data-testid={dataTestId}
          endAdornment={
            <InputAdornment position='end'>
              <StyledIconButton
                allWhite={allWhite}
                aria-label='toggle password visibility'
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}>
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </StyledIconButton>
            </InputAdornment>
          }
          id={id}
          name={name}
          onChange={onChange}
          onKeyDown={onKeyDown}
          type={showPassword ? 'text' : 'password'}
          value={value}
          inputProps={inputProps}
        />
      </StyledFormControl>
    )
  }
)

export default PasswordField
