import React from 'react'
import { Switch, Route } from 'react-router-dom'

import { LoginScreen, ResetPasswordScreen, UnsubscribeScreen } from '../screens'

const Main = () => {
  return (
    <main>
      <Switch>
        <Route component={ResetPasswordScreen} exact path='/resetPassword' />
        <Route
          component={UnsubscribeScreen}
          exact
          path='/unsubscribe/:contactKey/:studentId'
        />
        <Route component={LoginScreen} path='/' />
      </Switch>
    </main>
  )
}

export default Main
