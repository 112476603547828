import React, { useRef, useState } from 'react'
import { Redirect } from 'react-router-dom'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { Controller, useForm } from 'react-hook-form'
import { Box, FormControl, FormHelperText, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

import { ROOT } from '../../constants'
import ES_Logo from '../../images/ESLogo_white.png'
import triangles from '../../images/blue_triangles_rotated.png'
import { Button, FlexColumn, LinkButton, Paper, PasswordField } from '../shared'

import Loading from '../Login/Loading'

const Container = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,

  width: '100%',
  height: '100vh',

  [theme.breakpoints.up('sm')]: {
    backgroundImage: `url(${triangles})`,
    backgroundPosition: 'bottom right',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'auto 50%'
  }
}))

const Logo = styled('img')(({ theme }) => ({
  maxWidth: '90vw'
}))

const StyledForm = styled('form')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center'
}))

const ResetPassword = (props) => {
  const { control, errors, handleSubmit, watch } = useForm({
    mode: 'onBlur'
  })

  const { enqueueSnackbar } = useSnackbar()

  const [error, setError] = useState('')
  const [posting, setPosting] = useState(false)
  const [shouldRedirect, setShouldRedirect] = useState(false)

  const password = useRef({})
  password.current = watch('password', '')

  const passwordConfirm = useRef({})
  passwordConfirm.current = watch('passwordConfirm', '')

  const onSubmit = (data) => {
    setPosting(true)

    const searchParam = '?resetToken='
    let resetToken = props.location.search
    resetToken = resetToken.slice(searchParam.length, resetToken.length)

    axios
      .post(`${ROOT}password/reset`, {
        password: password.current,
        resetToken
      })
      .then((response) => {
        setPosting(false)

        enqueueSnackbar('Your password has been updated.', {
          variant: 'success'
        })

        setShouldRedirect(true)
      })
      .catch((error) => {
        setError(
          'There was a problem saving your password.  You will need to request a new Forgot Password email and try again.'
        )

        setPosting(false)
      })
  }

  if (posting) return <Loading text='Saving New Password' />
  if (shouldRedirect) return <Redirect to='/' />

  return (
    <Container>
      <Logo
        alt='Enriching Students'
        src={ES_Logo}
        sx={{
          height: 62,
          width: 312,
          marginTop: 2,
          marginLeft: 2
        }}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 'calc(100vh - 88px)'
        }}>
        <Paper
          sx={{
            maxWidth: '90%',
            width: 400,
            height: 300
          }}>
          {error ? (
            <Box sx={{ marginTop: 2, marginLeft: 1, marginRight: 1 }}>
              <Typography>{error}</Typography>
              <Box
                sx={{
                  marginTop: 2,
                  display: 'flex',
                  justifyContent: 'center'
                }}>
                <LinkButton label='Back to Login' to='/' />
              </Box>
            </Box>
          ) : (
            <StyledForm onSubmit={handleSubmit(onSubmit)}>
              <FlexColumn>
                <h2 style={{ textAlign: 'center' }}>Reset Password</h2>
                <FormControl error={Boolean(errors.password)}>
                  <Controller
                    as={
                      <PasswordField
                        dataTestId='password'
                        error={Boolean(errors.password)}
                        label='Password'
                      />
                    }
                    control={control}
                    defaultValue=''
                    name='password'
                    rules={{
                      required: 'Please enter a Password',
                      minLength: {
                        value: 8,
                        message: 'A Password must have at least 8 characters'
                      }
                    }}
                  />
                  <FormHelperText>
                    {errors.password ? errors.password.message : null}
                  </FormHelperText>
                </FormControl>
                <FormControl error={Boolean(errors.passwordConfirm)}>
                  <Controller
                    as={
                      <PasswordField
                        dataTestId='confirm-password'
                        error={Boolean(errors.passwordConfirm)}
                        id='passwordConfirm'
                        label='Confirm Password'
                      />
                    }
                    control={control}
                    defaultValue=''
                    name='passwordConfirm'
                    rules={{
                      validate: (value) =>
                        value === password.current ||
                        'The passwords do not match'
                    }}
                  />
                  <FormHelperText>
                    {errors.passwordConfirm
                      ? errors.passwordConfirm.message
                      : null}
                  </FormHelperText>
                </FormControl>
                <Button
                  color='secondary'
                  label='Save'
                  marginTop
                  type='submit'
                />
              </FlexColumn>
            </StyledForm>
          )}
        </Paper>
      </Box>
    </Container>
  )
}

export default ResetPassword
