import axios from 'axios'
import { useEffect, useState } from 'react'
import { ROOT } from '../../../constants'

export const useMessagesQuery = () => {
  const [isLoadingMessages, setIsLoadingMessages] = useState(false)
  const [messages, setMessages] = useState([])

  const fetchMessages = async () => {
    setIsLoadingMessages(true)

    try {
      const response = await axios.get(`${ROOT}message/forlogin`)

      setMessages(response.data)
      setIsLoadingMessages(false)
    } catch (error) {
      setIsLoadingMessages(false)
    }
  }

  useEffect(() => {
    fetchMessages()
  }, [])

  return { isLoadingMessages, messages }
}
