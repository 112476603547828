import React from 'react'
import { Paper, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

import { loginStatuses } from '../../constants'
import { Button, List, Popover } from '../shared'

import { validateLoginStatus } from './validateLoginStatus'
import { Warning } from '@mui/icons-material'

const SchoolHeading = styled(Typography)(({ theme }) => ({
  textAlign: 'start',
  padding: theme.spacing(),

  fontWeight: 'bold',
  fontSize: '18px'
}))

const SchoolItemBrandName = styled(Typography)(() => ({
  gridColumn: '1/8',
  textAlign: 'start'
}))

const SchoolItemContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isActive' && prop !== 'isLastItem'
})(({ isActive, isLastItem, theme }) => ({
  display: 'flex',
  alignItems: 'center',

  justifyContent: isActive && 'space-between',

  borderBottom: !isLastItem && '1px solid #d9d9d9',
  paddingTop: theme.spacing(),
  paddingBottom: theme.spacing()
}))

const SchoolUsersPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),

  width: 'calc(100vw - 16px)',

  [theme.breakpoints.up('md')]: {
    width: 'calc(50vw - 32px)'
  }
}))

const Item = ({
  emailAddress,
  isActive,
  isLastItem,
  loginStatus,
  redirectUrl,
  schoolName
}) => {
  const handleLoginClick = () => {
    window.location.href = redirectUrl
  }

  return (
    <SchoolItemContainer isActive={isActive} isLastItem={isLastItem}>
      {!isActive && (
        <Popover content={validateLoginStatus({ emailAddress, loginStatus })}>
          <Warning />
        </Popover>
      )}
      <SchoolItemBrandName style={{ paddingLeft: 8 }}>
        {schoolName}
      </SchoolItemBrandName>
      {isActive && (
        <div>
          <Button label='Login' onClick={handleLoginClick} />
        </div>
      )}
    </SchoolItemContainer>
  )
}

const MultiSchools = ({ formState, handleCancelClick, users }) => {
  const activeSchools = users.filter(
    (user) => user.loginStatus === loginStatuses.succeeded
  )
  const inactiveSchools = users.filter(
    (user) => user.loginStatus !== loginStatuses.succeeded
  )

  return (
    <SchoolUsersPaper>
      <Typography>
        The email address provided is associated with multiple schools.
      </Typography>
      {activeSchools.length > 0 && (
        <>
          <SchoolHeading>Your Schools</SchoolHeading>
          <List
            Component={Item}
            data={activeSchools}
            emailAddress={formState.emailAddress}
            isActive={true}
          />
        </>
      )}
      {inactiveSchools.length > 0 && (
        <>
          <Typography sx={{ paddingTop: 1, paddingBottom: 1 }}>
            <b>Please Note:</b> The following schools can't be currently
            accessed with your account:
          </Typography>
          <List
            Component={Item}
            data={inactiveSchools}
            emailAddress={formState.emailAddress}
            isActive={false}
          />
        </>
      )}
      <Button
        esButtonClass='cancelButton'
        label='Cancel'
        onClick={handleCancelClick}
      />
    </SchoolUsersPaper>
  )
}

export default MultiSchools
