import React, { Fragment } from 'react'
import EmptyArrayMessage from './EmptyArrayMessage'

const List = ({
  data,
  Component,
  emptyArrayMessage,
  emptyArrayObject,
  keyValue,
  textAlign,
  ...otherProps
}) => {
  const renderMessage = () => {
    if (typeof emptyArrayMessage === 'string') {
      return (
        <EmptyArrayMessage text={emptyArrayMessage} textAlign={textAlign} />
      )
    }

    if (typeof emptyArrayMessage === 'object') {
      return emptyArrayMessage.map((message) => (
        <EmptyArrayMessage key={message} text={message} textAlign={textAlign} />
      ))
    }

    return null
  }

  return (
    <Fragment>
      {data.length > 0 ? (
        <Fragment>
          {data.map((item, index) => (
            <Component
              key={keyValue === undefined ? index : item[keyValue]}
              index={index}
              isLastItem={index === data.length - 1 ? true : false}
              {...item}
              {...otherProps}
            />
          ))}
        </Fragment>
      ) : (
        renderMessage()
      )}
    </Fragment>
  )
}

export default List
