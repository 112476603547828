import React from 'react'
import { TextField } from '@mui/material'

const EsTextField = ({ dataTestId, ...otherProps }) => {
  return (
    <TextField
      inputProps={{ 'data-testid': `${dataTestId}` }}
      {...otherProps}
    />
  )
}

export default EsTextField
