import React from 'react'
import { Link } from 'react-router-dom'
import { styled } from '@mui/material/styles'

import Button from './Button'

const StyledLinkButton = styled(Link)(() => ({
  textDecoration: 'none'
}))

const LinkButton = ({ esButtonClass = 'greenButton', label, to }) => {
  return (
    <StyledLinkButton to={to}>
      <Button disableElevation esButtonClass={esButtonClass} label={label} />
    </StyledLinkButton>
  )
}

export default LinkButton
