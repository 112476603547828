import React from 'react'
import { Button } from '@mui/material'
import { styled } from '@mui/material/styles'

export const StyledButton = styled(Button, {
  shouldForwardProp: (prop) =>
    prop !== 'marginTop' &&
    prop !== 'marginRight' &&
    prop !== 'marginBottom' &&
    prop !== 'marginLeft'
})(({ marginBottom, marginLeft, marginRight, marginTop, theme }) => ({
  fontSize: 13,

  maxWidth: 250,

  paddingTop: 4,
  paddingRight: 10,
  paddingBottom: 4,
  paddingLeft: 10,

  marginTop: marginTop && theme.spacing(),
  marginRight: marginRight && theme.spacing(),
  marginBottom: marginBottom && theme.spacing(),
  marginLeft: marginLeft && theme.spacing()
}))

export const CancelButton = styled(StyledButton)(({ theme }) => ({
  background: 'transparent',
  color: '#191b24',

  '&:hover': {
    background: '#f1f1f1'
  }
}))

export const DeleteButton = styled(StyledButton)(({ theme }) => ({
  backgroundColor: theme.palette.error.main,
  color: '#fff',

  '&:hover': {
    backgroundColor: theme.palette.error.main,
    color: '#fff'
  }
}))

export const GreyButton = styled(StyledButton)(({ theme }) => ({
  backgroundColor: '#f1f1f1',
  color: '#191b24',

  '&:hover': {
    backgroundColor: '#f1f1f1',
    color: '#191b24'
  }
}))

export const GreenButton = styled(StyledButton)(({ theme }) => ({
  backgroundColor: theme.palette.success.main,
  color: '#fff',

  '&:hover': {
    backgroundColor: theme.palette.success.main,
    color: '#fff'
  }
}))

const EsButton = ({
  dataTestId,
  disabled,
  disableElevation = true,
  esButtonClass = 'greenButton',
  htmlFor,
  label,
  onClick,
  marginBottom,
  marginLeft,
  marginRight,
  marginTop,
  startIcon,
  sx,
  type = 'button',
  variant = 'contained'
}) => {
  if (esButtonClass === 'cancelButton') {
    return (
      <CancelButton
        data-testid={dataTestId}
        disabled={disabled}
        disableElevation={disableElevation}
        htmlFor={htmlFor}
        marginBottom={marginBottom}
        marginLeft={marginLeft}
        marginRight={marginRight}
        marginTop={marginTop}
        onClick={onClick}
        startIcon={startIcon}
        sx={sx}
        type={type}
        variant={variant}>
        {label}
      </CancelButton>
    )
  }

  if (esButtonClass === 'deleteButton') {
    return (
      <DeleteButton
        data-testid={dataTestId}
        disabled={disabled}
        disableElevation={disableElevation}
        htmlFor={htmlFor}
        marginBottom={marginBottom}
        marginLeft={marginLeft}
        marginRight={marginRight}
        marginTop={marginTop}
        onClick={onClick}
        startIcon={startIcon}
        sx={sx}
        type={type}
        variant={variant}>
        {label}
      </DeleteButton>
    )
  }

  if (esButtonClass === 'greyButton') {
    return (
      <GreyButton
        data-testid={dataTestId}
        disabled={disabled}
        disableElevation={disableElevation}
        htmlFor={htmlFor}
        marginBottom={marginBottom}
        marginLeft={marginLeft}
        marginRight={marginRight}
        marginTop={marginTop}
        onClick={onClick}
        startIcon={startIcon}
        sx={sx}
        type={type}
        variant={variant}>
        {label}
      </GreyButton>
    )
  }

  if (esButtonClass === 'greenButton') {
    return (
      <GreenButton
        data-testid={dataTestId}
        disabled={disabled}
        disableElevation={disableElevation}
        htmlFor={htmlFor}
        marginBottom={marginBottom}
        marginLeft={marginLeft}
        marginRight={marginRight}
        marginTop={marginTop}
        onClick={onClick}
        startIcon={startIcon}
        sx={sx}
        type={type}
        variant={variant}>
        {label}
      </GreenButton>
    )
  }

  return (
    <StyledButton
      data-testid={dataTestId}
      disabled={disabled}
      disableElevation={disableElevation}
      htmlFor={htmlFor}
      marginBottom={marginBottom}
      marginLeft={marginLeft}
      marginRight={marginRight}
      marginTop={marginTop}
      onClick={onClick}
      startIcon={startIcon}
      sx={sx}
      type={type}
      variant={variant}>
      {label}
    </StyledButton>
  )
}

export default EsButton
