import React from 'react'
import { styled } from '@mui/material/styles'

const StyledFlexColumn = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column'
}))

const FlexColumn = ({ children, as, sx }) => {
  return (
    <StyledFlexColumn as={as} sx={sx}>
      {children}
    </StyledFlexColumn>
  )
}

export default FlexColumn
