export const loginStatuses = {
  schoolPreventsStudentLogin: -21,
  onlyStudentMayLogOnToStudentApp: -16,
  subIsNotSetToImpersonate: -15,
  cleverDidNotReturnEmailAddress: -14,
  cleverDidNotAuthorize: -13,
  cleverTokenNotProvided: -12,
  loginTokensExpired: -11,
  emailDoesNotExistInEnrichingStudents: -10,
  unableToValidateGoogleCredentials: -9,
  failedDueToErrorWithGoogle: -8,
  failedDueToTooManyActiveAccounts: -7,
  failedDueToAccountBeingInactiveOrSoftDeleted: -6,
  failedDueToSiteBeingInTestMode: -5,
  failedDueToAccountLockedOut: -4,
  failedDueToSchoolAccountInactive: -3,
  failedDueToBadEmail: -1,
  unknown: 0,
  passwordIsValid: 1,
  succeeded: 2
}
