import React from 'react'
import { Button, Popover } from '@mui/material'
import { styled } from '@mui/material/styles'

const Content = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(),
  paddingRight: theme.spacing(2),
  paddingBottom: theme.spacing(),
  paddingLeft: theme.spacing(2),

  maxWidth: 800
}))

const SimplePopover = ({ children, content, dataTestId, sx }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  function handleClick(event) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <>
      <Button
        aria-describedby={id}
        data-testid={dataTestId}
        onClick={handleClick}
        sx={sx}>
        {children}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}>
        <Content dangerouslySetInnerHTML={{ __html: content }} />
      </Popover>
    </>
  )
}

export default SimplePopover
