export const sortByCollatingField = (ascValue, field) => {
  //constructor for objects that enable
  //language sensitive string comparison
  const collator = new Intl.Collator('en', {
    numeric: true,
    sensitivity: 'base'
  })

  if (ascValue) {
    return (a, b) => collator.compare(a[field], b[field])
  } else {
    return (a, b) => collator.compare(b[field], a[field])
  }
}
