import { useEffect, useRef, useState } from 'react'

export const useMessagesHeight = ({ messages }) => {
  const [messagesHeight, setMessagesHeight] = useState(0)
  const messagesRef = useRef(null)

  useEffect(() => {
    if (messages.length > 0) {
      setMessagesHeight(messagesRef.current.clientHeight)
    }
  }, [messages.length])

  return { messagesHeight, messagesRef }
}
