import React from 'react'
import { CircularProgress, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledLoading = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  backgroundColor: '#eee',

  [theme.breakpoints.up('md')]: {
    height: '100vh'
  }
}))

const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.primary.main
}))

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2)
}))

const Loading = ({ dataTestId = 'loading-component', sx, text }) => {
  return (
    <StyledLoading data-testid={dataTestId} sx={sx}>
      <StyledCircularProgress size={80} />
      <StyledTypography variant='h4' align='center'>
        {text}
      </StyledTypography>
    </StyledLoading>
  )
}

export default Loading
